import Vue from "vue";
import Vuex from "vuex";
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    config: {},
    user: {},
    logged: false,
    roles: [],
    permissions: [],
    sale_lock: {
      own: true,
      name: '',
      status: false,
      icon: 'unlock',
      type: 'primary'
    },
    sale_event_lock: {
      own: true,
      name: '',
      status: false,
      icon: 'unlock',
      type: 'primary'
    },
    options: {
      agents: [],
      business_unit_groups: [],
      business_units: [],
      cancel_reasons: [],
      document_types: [],
      error_types: [],
      nationalities: [],
      activities: [],
      offers: [],
      pay_types: [],
      permissions: [],
      product_families: [],
      products: [],
      provinces: [],
      roles: [],
      role_sale_status_observations: [],
      sale_event_discounts: [],
      sale_event_error_types: [],
      sale_event_products: [],
      sale_event_rate_groups: [],
      sale_event_rates: [],
      sale_product_statuses: [],
      sale_status_families: [],
      sale_statuses: [],
      select_products: [],
      productGroupFamilies: [],
      productGroups: [],
      productGroupProducts: [],
      telcos: []
    }
  },
  getters: {
    config: state => {
      return state.config
    },
    user: state => {
      return state.user
    },
    logged: state => {
      return state.logged
    },
    roles: state => {
      return state.roles
    },
    permissions: state => {
      return state.permissions
    },
    role_sale_status_observations: state => {
      return state.role_sale_status_observations
    },
    options: state => {
      return state.options
    },
    sale_lock: state => {
      return state.sale_lock
    },
    sale_event_lock: state => {
      return state.sale_event_lock
    },
    select_products: state => {
      let tmp = {}
      for (let product of state.options.select_products) {
        tmp[product.id] = product
      }
      return tmp
    },
    filteredProducts (state) {
      return (family_name, business_unit_id) => {
        let tmp = []
        for (let family of state.options.product_families) {
          if ((family.name === family_name) && (family.business_unit_id === business_unit_id)) {
            for (let product of state.options.products) {
              if ((product.active) && (family.id === product.product_family_id)) {
                tmp.push(product)
              }
            }
          }
        }
        return tmp
      }
    },
    filteredProductFamilies (state) {
      return (business_unit_id) => {
        let tmp = []
        for (let family of state.options.product_families) {
          if (family.business_unit_id === business_unit_id) {
            tmp.push(family)
          }
        }
        return tmp
      }
    },
    find_in_family (state) {
      return (family, id) => {
        return state.options[family].find(x => x.id === id)
      }
    }
  },
  actions: {
    async loadConfig ({ commit }) {
      return axios.get('/config.json').then(config => {
        commit('config', config.data)
        return config
      })
    },
    initOptions: ({commit, state, dispatch}) => {
      dispatch('refreshRoleList')
      dispatch('refreshPermissionList')
      dispatch('refreshRoleSaleStatusObservationsList')
      dispatch('refreshProductList')

      let payload = {role_id: state.config.roles.agent, active: 1}
      axios.get('/api/v1/users', {params: payload}).then(response => {
        commit('agents', response.data)
      })
      axios.get('/api/v1/business_unit_groups').then(response => {
        commit('business_unit_groups', response.data)
      })
      axios.get('/api/v1/business_units').then(response => {
        commit('business_units', response.data)
      })
      axios.get('/api/v1/cancel_reasons').then(response => {
        commit('cancel_reasons', response.data)
      })
      axios.get('/api/v1/document_types').then(response => {
        commit('document_types', response.data)
      })
      axios.get('/api/v1/error_types').then(response => {
        commit('error_types', response.data)
      })
      axios.get('/api/v1/nationalities').then(response => {
        commit('nationalities', response.data)
      })
      axios.get('/api/v1/activities').then(response => {
        commit('activities', response.data)
      })
      axios.get('/api/v1/offers?active=1').then(response => {
        commit('offers', response.data)
      })
      axios.get('/api/v1/pay_types').then(response => {
        commit('pay_types', response.data)
      })
      axios.get('/api/v1/provinces?active=1').then(response => {
        commit('provinces', response.data)
      })
      axios.get('/api/v1/sale_product_statuses').then(response => {
        commit('sale_product_statuses', response.data)
      })
      axios.get('/api/v1/sale_statuses').then(response => {
        commit('sale_statuses', response.data)
      })
      axios.get('/api/v1/sale_status_families').then(response => {
        commit('sale_status_families', response.data)
      })
      axios.get('/api/v1/telcos').then(response => {
        commit('telcos', response.data)
      })
    },
    refreshProductList: ({ commit }) => {
      axios.get('/api/v1/product_group_products').then(response => {
        commit('productGroupProducts', response.data)
      })
      axios.get('/api/v1/product_group_families').then(response => {
        commit('productGroupFamilies', response.data)
      })
      axios.get('/api/v1/product_families').then(response => {
        commit('product_families', response.data)
      })
      axios.get('/api/v1/product_groups').then(response => {
        commit('productGroups', response.data)
      })
      axios.get('/api/v1/products').then(response => {
        commit('products', response.data)
      })
      axios.get('/api/v1/products?view=1').then(response => {
        commit('select_products', response.data)
      })
    },
    refreshRoleList: ({ commit }) => {
      axios.get('/api/v1/roles').then(response => {
        commit('roles', response.data)
      })
    },
    refreshPermissionList: ({ commit }) => {
      axios.get('/api/v1/permissions').then(response => {
        commit('permissions', response.data)
      })
    },
    refreshRoleSaleStatusObservationsList: ({ commit, state }) => {
      axios.get('/api/v1/role_sale_status_observations/role/' + state.user.role_id).then(response => {
        commit('role_sale_status_observations', response.data)
      })
    },
    refreshSaleEventLists: ({ commit }) => {
      axios.get('/api/v1/sale_event_discounts').then(response => {
        commit('sale_event_discounts', response.data)
      })
      axios.get('/api/v1/sale_event_error_types').then(response => {
        commit('sale_event_error_types', response.data)
      })
      axios.get('/api/v1/sale_event_products').then(response => {
        commit('sale_event_products', response.data)
      })
      axios.get('/api/v1/sale_event_rate_groups').then(response => {
        commit('sale_event_rate_groups', response.data)
      })
      axios.get('/api/v1/sale_event_rates').then(response => {
        commit('sale_event_rates', response.data)
      })
    },
    async getSaleLock ({ commit, state }, id) {
      let sale_lock = {
        location: 'existing_lock'
      }
      await axios.get('/api/v1/sales/' + id + '/locks').then(response => {
        sale_lock.name = ''
        sale_lock.own = true
        sale_lock.status = false
        sale_lock.icon = 'unlock'
        sale_lock.type = 'primary'
        if (response.data !== null && response.data !== undefined) {
          sale_lock.name = response.data.name
          sale_lock.status = true
          sale_lock.icon = 'lock'
          sale_lock.created_at = new Date(Date.parse(response.data.created_at)).toLocaleString()
          if (response.data.user_id === state.user.id) {
            sale_lock.type = 'success'
          } else {
            sale_lock.own = false
            sale_lock.type = 'danger'
          }
        }
        commit('sale_lock', sale_lock)
      })
    },
    async setSaleLock ({ commit, state, dispatch }, id) {
      let sale_lock = {}
      await dispatch('getSaleLock', id)
      let locked = state.sale_lock.status
      if (locked) {
        await axios.delete('/api/v1/sales/' + id + '/locks').then(() => {
          sale_lock.own = true
          sale_lock.status = false
          sale_lock.icon = 'unlock'
          sale_lock.type = 'primary'
          commit('sale_lock', sale_lock)
        })
      } else {
        var payload = {
          user_id: state.user.id
        }
        await axios.post('/api/v1/sales/' + id + '/locks', payload).then(response => {
          sale_lock.status = true
          sale_lock.icon = 'lock'
          sale_lock.created_at = new Date().toLocaleString()
          if (response.data) {
            sale_lock.own = true
            sale_lock.name = state.user.name
            sale_lock.type = 'success'
          } else {
            sale_lock.own = false
            sale_lock.type = 'danger'
          }
          commit('sale_lock', sale_lock)
        })
      }
    },
    resetSaleLock({ commit }) {
      let defaultLock = {
        own: true,
        name: '',
        status: false,
        icon: 'unlock',
        type: 'primary'
      }
      commit('sale_lock', defaultLock)
    },
    async getSaleEventLock ({ commit, state }, id) {
      let sale_event_lock = {
        location: 'existing_lock'
      }
      await axios.get('/api/v1/sale_events/' + id + '/locks').then(response => {
        sale_event_lock.name = ''
        sale_event_lock.own = true
        sale_event_lock.status = false
        sale_event_lock.icon = 'unlock'
        sale_event_lock.type = 'primary'
        if (response.data !== null && response.data !== undefined) {
          sale_event_lock.name = response.data.name
          sale_event_lock.status = true
          sale_event_lock.icon = 'lock'
          sale_event_lock.created_at = new Date(Date.parse(response.data.created_at)).toLocaleString()
          if (response.data.user_id === state.user.id) {
            sale_event_lock.type = 'success'
          } else {
            sale_event_lock.own = false
            sale_event_lock.type = 'danger'
          }
        }
        commit('sale_event_lock', sale_event_lock)
      })
    },
    async setSaleEventLock ({ commit, state, dispatch }, id) {
      let sale_event_lock = {}
      await dispatch('getSaleEventLock', id)
      let locked = state.sale_event_lock.status
      if (locked) {
        await axios.delete('/api/v1/sale_events/' + id + '/locks').then(() => {
          sale_event_lock.own = true
          sale_event_lock.status = false
          sale_event_lock.icon = 'unlock'
          sale_event_lock.type = 'primary'
          commit('sale_event_lock', sale_event_lock)
        })
      } else {
        var payload = {
          user_id: state.user.id
        }
        await axios.post('/api/v1/sale_events/' + id + '/locks', payload).then(response => {
          sale_event_lock.status = true
          sale_event_lock.icon = 'lock'
          sale_event_lock.created_at = new Date().toLocaleString()
          if (response.data) {
            sale_event_lock.own = true
            sale_event_lock.name = state.user.name
            sale_event_lock.type = 'success'
          } else {
            sale_event_lock.own = false
            sale_event_lock.type = 'danger'
          }
          commit('sale_event_lock', sale_event_lock)
        })
      }
    },
    resetSaleEventLock({ commit }) {
      let defaultLock = {
        own: true,
        name: '',
        status: false,
        icon: 'unlock',
        type: 'primary'
      }
      commit('sale_event_lock', defaultLock)
    }
  },
  mutations: {
    config: (state, config) => {
      state.config = config
    },
    roles: (state, list) => {
      state.roles = list
    },
    permissions: (state, list) => {
      state.permissions = list
    },
    role_sale_status_observations: (state, list) => {
      state.role_sale_status_observations = list
    },
    agents: (state, list) => {
      state.options.agents = list
    },
    business_unit_groups: (state, list) => {
      state.options.business_unit_groups = list
    },
    business_units: (state, list) => {
      state.options.business_units = list
    },
    cancel_reasons: (state, list) => {
      state.options.cancel_reasons = list
    },
    document_types: (state, list) => {
      state.options.document_types = list
    },
    error_types: (state, list) => {
      state.options.error_types = list
    },
    nationalities: (state, list) => {
      state.options.nationalities = list
    },
    activities: (state, list) => {
      state.options.activities = list
    },
    offers: (state, list) => {
      state.options.offers = list
    },
    pay_types: (state, list) => {
      state.options.pay_types = list
    },
    products: (state, list) => {
      state.options.products = list
    },
    product_families: (state, list) => {
      state.options.product_families = list
    },
    provinces: (state, list) => {
      state.options.provinces = list
    },
    sale_product_statuses: (state, list) => {
      state.options.sale_product_statuses = list
    },
    sale_status_families: (state, list) => {
      state.options.sale_status_families = list
    },
    sale_statuses: (state, list) => {
      state.options.sale_statuses = list
    },
    sale_event_discounts: (state, list) => {
      state.options.sale_event_discounts = list
    },
    sale_event_error_types: (state, list) => {
      state.options.sale_event_error_types = list
    },
    sale_event_products: (state, list) => {
      state.options.sale_event_products = list
    },
    sale_event_rate_groups: (state, list) => {
      state.options.sale_event_rate_groups = list
    },
    sale_event_rates: (state, list) => {
      state.options.sale_event_rates = list
    },
    select_products: (state, list) => {
      state.options.select_products = list
    },
    productGroupFamilies: (state, list) => {
      state.options.productGroupFamilies = list
    },
    productGroups: (state, list) => {
      state.options.productGroups = list
    },
    productGroupProducts: (state, list) => {
      state.options.productGroupProducts = list
    },
    telcos: (state, list) => {
      state.options.telcos = list
    },
    sale_lock: (state, lock) => {
      state.sale_lock = lock
    },
    sale_event_lock: (state, lock) => {
      state.sale_event_lock = lock
    },
    login: (state, user) => {
      state.user = user
      // state.user.permissions.forEach(perm => {
      //   state.user[perm] = true
      // })
      state.logged = true
    },
    logout (state) {
      state.user = {}
      state.logged = false
    }
  }
});
