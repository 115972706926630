<template>
  <header>

    <a href="#" class="logo">
      <img src="../assets/logos/logo_home.png"/>
    </a>

    <input type="checkbox" id="menu-bar">
    <label for="menu-bar" class="fas fa-bars"></label>

    <nav class="navbar">

      <a href="javascript: void(0)" @click="toLogout">Cerrar Sesión</a>
    </nav>

  </header>
  <!--  <div id="topnavbar">-->
  <!--    <el-menu class="metalyellow" mode="horizontal" @select="loadRoute">-->
  <!--      <span class="app-title">-->
  <!--        <img class="horizontalalign" :src="config.logo.url" style="height: 30px;">-->
  <!--      </span>-->
  <!--      <el-tooltip placement="bottom-start" width="200" trigger="hover" content="Cobertura" :enterable="false">-->
  <!--        <el-menu-item index="/coverage">-->
  <!--          <icon scale="1.2" name="signal"></icon>-->
  <!--        </el-menu-item>-->
  <!--      </el-tooltip>-->
  <!--      <el-submenu index="" class="user-submenu">-->
  <!--        <template slot="title">-->
  <!--          <b>{{ "Hola, " + user.name }}</b>-->
  <!--        </template>-->
  <!--        <el-menu-item index="reload">-->
  <!--          <div class="verticalalign">-->
  <!--            <icon scale="1.1" name="sync" style="padding-right:5px"></icon>-->
  <!--            <span>Recargar</span>-->
  <!--          </div>-->
  <!--        </el-menu-item>-->

  <!--        <el-menu-item index="logout">-->
  <!--          <div class="verticalalign">-->
  <!--            <icon scale="1.1" name="sign-out-alt" style="padding-right:5px"></icon>-->
  <!--            <span>Cerrar Sesión</span>-->
  <!--          </div>-->
  <!--        </el-menu-item>-->
  <!--      </el-submenu>-->
  <!--    </el-menu>-->
  <!--  </div>-->
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'top-nav-bar',
  methods: {
    loadRoute(index) {
      switch (index) {
        case 'reload':
          this.reLoad()
          break
        case 'logout':
          this.toLogout()
          break
          // case 'vista':
          //   this.openVista()
          //   break
        default:
          this.$router.push(index)
      }
    },
    toLogout() {
      let vm = this
      vm.$http.get('/d-api/logout').then(response => {
        localStorage.removeItem('token')
        vm.$http.defaults.headers.common['Authorization'] = ''
        vm.$store.commit('logout')
        vm.$router.push('/login')
      })
      // this.$http.delete('/api/v1/sales/locks/' + this.user.id).then(() => {
      //   this.$http.delete('/auth/logout').then(response => {
      //     if (response.status === 200) {
      // }
      // })
      // })
    },
    reLoad() {
      window.onbeforeunload = null
      location.reload(true)
    },
    // openToken () {
    //   this.$alert(this.user.api_token, 'Token', {
    //     confirmButtonText: 'Cerrar'
    //   })
    // },
    // openVista () {
    //   this.$http.get('/api/v1/telesales/vista/url').then((response) => {
    //     if (response.status === 200) {
    //       window.open(response.data.url)
    //     }
    //   })
    // },
  },
  computed: {
    ...mapGetters(['user', 'config'])
  }
}
</script>

<style>
/*.app-title {*/
/*  float: left;*/
/*  font-size: 25px;*/
/*  padding: 5px 20px 0px 35px;*/
/*  color: white;*/
/*}*/

/*#topnavbar .el-menu--horizontal .el-submenu .el-submenu__title {*/
/*  height: 40px;*/
/*  line-height: 40px;*/
/*  color: #696969;*/
/*}*/

/*#topnavbar .el-menu--horizontal .el-menu-item {*/
/*  height: 40px;*/
/*  line-height: 45px;*/
/*  color: #696969;*/
/*}*/

/*.el-menu--horizontal .el-menu-item:hover, .el-menu--horizontal .el-submenu .el-submenu__title:hover {*/
/*  color: #696969 !important;*/
/*  background-color: #FFF;*/
/*}*/

/*.el-menu--horizontal .el-menu-item:focus, .el-menu--horizontal .el-menu-item:hover, .el-menu--horizontal .el-menu-item.is-active, .el-menu--horizontal .el-submenu__title.is-active, .el-submenu.is-active .el-menu .el-menu-item.is-active:hover {*/
/*  color: #6e247b !important;*/
/*  background-color: #FFF;*/
/*  border-bottom: 2px solid #6e247b !important;*/
/*}*/

/*.el-menu.el-menu--horizontal {*/
/*  border-bottom: none;*/
/*}*/

/*.user-submenu {*/
/*  float: right !important;*/
/*}*/

/*.el-message-box__title, .el-message-box__content {*/
/*  font-family: Arial, Helvetica, sans-serif;*/
/*}*/

/*.el-menu--horizontal .el-submenu >>> .el-menu {*/
/*  top: auto;*/
/*  right: 0;*/
/*  left: auto;*/
/*}*/

/*.logo-container {*/
/*  display: flex;*/
/*  flex-grow: 1;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*}*/
@import "../../static/navbar/style.css";
</style>
