<template>
  <div>
    <el-row>
      <transition-group name="fade" mode="out-in">
        <el-col :span="2" key="cov-plat">
          <el-row class="filter-label">
            <b>Plataforma:</b>
          </el-row>

          <el-row>
            <el-select size="mini" v-model="filter.platform" placeholder="Plataforma" :disabled="show.portal" @change="setToken()">
              <el-option v-for="item in options.platforms" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-row>
        </el-col>

        <el-col :span="10" v-if="$root.hasData(filter.platform)" key="cov-add">
          <el-row class="filter-label">
            <b>Dirección:</b>
          </el-row>

          <el-row>
            <el-autocomplete size="mini" style="width: 100%;" class="inline-input" v-model="filter.address" :disabled="show.portal" :fetch-suggestions="querySearchAddress" placeholder="Escribe la dirección a buscar" :trigger-on-focus="false" @select="handleSelect"></el-autocomplete>
          </el-row>
        </el-col>

        <el-col :span="10" v-if="show.portal" key="cov-port">
          <el-row class="filter-label">
            <b>Nº Portal:</b>
          </el-row>

          <el-row>
            <el-col>
              <el-col :span="4">
                <el-input size="mini" placeholder="Nº de portal" v-model="filter.portal" :disabled="(Object.keys(coverage).length > 0) || (Object.keys(error).length > 0)"></el-input>
              </el-col>

              <el-col :span="10" v-if="$root.hasData(filter.portal)">
                <el-button size="mini" class="blue_bg" round @click="resetData()" v-if="(Object.keys(coverage).length > 0) || (Object.keys(error).length > 0)">
                  Reiniciar filtros
                </el-button>
                <el-button size="mini" class="yellow_bg" round @click="secondChance()">
                  Consultar
                </el-button>
              </el-col>
            </el-col>
          </el-row>
        </el-col>
      </transition-group>
    </el-row>

    <el-row v-if="show.floors">
      <el-row>
        <el-col>
          <el-table :data="verticals" @row-click='getCoverage' :default-sort="{prop: 'Floor'}" :height="300">
            <el-table-column sortable label="Letra" prop="Letter"></el-table-column>
            <el-table-column sortable label="Bloque" prop="Block"></el-table-column>
            <el-table-column sortable label="Planta" prop="Floor"></el-table-column>
            <el-table-column sortable label="Puerta" prop="Door"></el-table-column>
            <el-table-column sortable label="Mano 1" prop="Hand1"></el-table-column>
            <el-table-column sortable label="Mano 2" prop="Hand2"></el-table-column>
          </el-table>
        </el-col>
      </el-row>

      <el-row v-if="formattedCoverage" @click.native="$root.copyText(formattedCoverage.gescal)" class="handcursor purple_bg" style="padding: 5px;">
        <el-tooltip content="Haz click para copiar el código Gescal" effect="dark" placement="right">
          <b>Gescal: {{ formattedCoverage.gescal }}</b>
        </el-tooltip>
      </el-row>

      <el-row v-if="homeCoverage.characteristics.length > 0">
        <!-- <el-row v-if="formattedCoverage" :class="Object.keys(error).length > 0 ? 'red_bg' : 'green_bg'" style="padding: 5px;">
          <b>Resultado de cobertura: {{ formattedCoverage.tipoCobertura }}</b>
        </el-row> -->

        <el-row>
          <el-col>
            <el-table :data="homeCoverage.characteristics" style="width: 100%">
              <el-table-column label="Tecnología" prop="technology" width="100"></el-table-column>
              <el-table-column label="Propietario" prop="territoryOwner" width="100"></el-table-column>
              <el-table-column label="ID Dirección" prop="addressId" width="200"></el-table-column>
              <el-table-column label="Velocidad estimada" prop="speedEstimated"></el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </el-row>
    </el-row>

    <!-- <el-row>
      <el-col :span="6">
        <el-select v-model="floor" placeholder="Planta">
          <el-option v-for="item in options.floors" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-col>

      <el-col :span="6">
        <el-select v-model="hand" placeholder="Letra / Puerta" @change="setHomeCoverage()">
          <el-option v-for="item in options.hands[floor]" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-col>
    </el-row>

    <el-row v-if="homeCoverageFile && homeCoverageFile.length > 0">
      <el-col> Tecnología: {{ coverage['tipoCobertura']}}</el-col>
      <el-col v-for="(item, index) in homeCoverageFile[0]['characteristics']" :key="'char-' + index">
        {{ formatProperty(item.name) }}: {{ item.value}}
      </el-col>
    </el-row> -->
  </div>
</template>
<script>
export default {
  name: 'coverage',
  data () {
    return {
      api: 'https://ovid-coverage-component.prod-01.k8s.masmovil.com/component/api/',
      token: '',
      session: {},
      show: {
        portal: false,
        floors: false
      },
      filter: {
        platform: null,
        address: '',
        portal: null,
        floor: null,
        hand: null,
      },
      place: null,
      verticals: [],
      coverage: {},
      secondchance: {},
      formattedCoverage: {},
      filteredVerticals: [],
      homeCoverage: {
        characteristics: []
      },
      homeCoverageFile: null,
      homeNeba: false,
      coverages: {},
      error: {},
      options: {
        floors: [],
        hands: {},
        platforms: [{
          value: '1',
          label: 'MasMovil'
        }, {
          value: '2',
          label: 'Yoigo'
        }],
      }
    }
  },
  methods: {
    querySearchAddress (queryString, cb) {
      if (queryString.length < 3) return
      let params = {
        searchText: queryString,
        marca: this.session.marca,
        sessionId: this.session.sessionId,
        addGescal12: true,
        territoriesOwnerBlocked: this.session.territoriesOwnerBlocked,
        segmento: this.session.segmento,
        provinceId: '',
        ItemsAutoCompleteVal: 7
      }
      let headers = {
        accept: 'application/json',
        accesstoken: this.session.accessToken
      }
      this.$http.get(
        this.api + 'Search/GetAddress',
        { headers: headers, params: params }
      ).then((response) => {
        let streets = []
        for (let i in response.data.items) {
          let street = response.data.items[i]
          let address = street.address + ' (' + street.postalCode + ' ' + street.town + ' - ' + street.province + ')'
          streets.push({ value: address, item: street })
        }
        cb(streets)
      })
    },
    async secondChance () {
      let payload = {
        gescal12: this.place.gescal12,
        infoCalle: this.place.address,
        numero: this.filter.portal,
        marca: this.session.marca,
        sessionId: this.session.sessionId,
        territoriesOwnerBlocked: this.session.territoriesOwnerBlocked,
        segmento: this.session.segmento,
        provinceId: this.place.provinceId,
        channelsWithPriorities: true
      }

      let headers = {
        accept: 'application/json',
        accesstoken: this.session.accessToken
      }

      await this.$http.get(this.api + 'Search/GetSecondChanceCoverage', { headers: headers, params: payload }).then((response) => {
        this.secondchance = response.data
      }).catch((err) => {
        this.$message({
          message: 'Error al obtener la cobertura de segunda oportunidad',
          type: 'error'
        })
        this.error = err
        throw err
      })

      this.secondchance['@search.score'] = this.place['@search.score']

      payload = {
        SelectedStreet: this.secondchance,
        marca: this.session.marca,
        segmento: this.session.segmento,
        territoriesOwnerBlocked: this.session.territoriesOwnerBlocked,
        channelsWithPriorities: true
      }

      await this.$http.post(this.api + 'Filter/FilterCoverageInGescal17', payload, { headers: headers }).then((response) => {
        this.coverage = response.data
        this.formattedCoverage.gescal = this.coverage.gescal17
      }).catch((err) => {
        this.$message({
          message: 'Error al filtrar la cobertura',
          type: 'error'
        })
        this.error = err
        throw err
      })
      let verticals = JSON.parse(this.coverage.verticals)
      if ((verticals !== null) && (verticals.length === 0)) {
        verticals = JSON.parse(this.secondchance.verticals)
        this.coverage.verticals = this.secondchance.verticals
      }
      if (verticals != null){
        this.loadFloors()
        this.homeCoverageFile = null
        this.filterVerticals()
      } else {
        this.coverageFromFile()
      }
    },
    async coverageFromFile () {
      let payload = {}
      payload['gescal17'] = this.secondchance.gescal17
      payload['marca'] =  this.session.marca
      payload['segmento'] =  this.session.segmento
      payload['canal'] = 'MySIM'
      payload['province'] = this.secondchance.province
      payload['provinceId'] = this.secondchance.provinceId
      payload['town'] = this.secondchance.town
      payload['postalCode'] = this.secondchance.postalCode
      payload['wayType'] = this.secondchance.wayType
      payload['wayName'] = this.secondchance.wayName
      payload['num'] = this.secondchance.num
      payload['territoryOwners'] = this.secondchance.territoryOwners
      payload['sessionId'] = this.session.sessionId
      payload['channelsWithPriorities'] = true
      payload['adsl'] = this.coverage.adsl
      payload['ftth'] = this.coverage.ftth
      payload['territoryOwnerAdsl'] = this.coverage.territoryOwnerAdsl
      payload['territoryOwnerFtth'] = this.coverage.territoryOwnerFtth

      let headers = {
        accept: 'application/json',
        accesstoken: this.session.accessToken
      }

      await this.$http.post(this.api + 'Search/CoverageFromFile', payload, { headers: headers }).then((response) => {
        if (response.data.cobertura.length > 0){
          this.homeCoverageFile = JSON.parse(response.data.cobertura)
          this.coverage = response.data
          this.homeCoverage = {
            characteristics: []
          }
          this.show.floors = true
        }
      }).catch((err) => {
        this.$message({
          message: 'Error al obtener la cobertura de archivo',
          type: 'error'
        })
        this.error = err
        throw err
      })
    },
    async filterVerticals () {
      let payload = {}
      payload['Verticals'] = JSON.parse(this.coverage.verticals)
      payload['Marca'] = this.session.marca
      payload['Segmento'] = this.session.segmento
      payload['AddAdsl'] = true
      payload['TerritoriesOwnerBlocked'] = this.session.territoriesOwnerBlocked
      payload['TerritoriesOwnerFTTH'] = this.coverage.territoryOwnerFtth
      payload['ChannelsWithPriorities'] = true

      let headers = {
        accept: 'application/json',
        accesstoken: this.session.accessToken
      }

      await this.$http.post(this.api + 'Filter/FilterVerticalsByMarcaAndSegmento', payload, { headers: headers }).then((response) => {
        if (response.data.length > 0){
          this.filteredVerticals = response.data
        }
      }).catch((err) => {
        this.$message({
          message: 'Error al obtener las verticales filtradas',
          type: 'error'
        })
        this.error = err
        throw err
      })
    },
    async getCoverage (row) {
      await this.getCoverageFormat(row)
      await this.getHomeCoverage()
    },
    async getCoverageFormat (vertical) {
      let payload = {}
      payload['gescal37'] = vertical.Gescal37
      payload['marca'] = this.session.marca
      payload['segmento'] = this.session.segmento
      payload['canal'] = 'MySIM'
      payload['tieneAdsl'] = this.coverage.adsl
      payload['tieneFtth'] = this.coverage.ftth
      payload['territoryOwnerAdsl'] = this.coverage.territoryOwnerAdsl
      payload['territoryOwnerFtth'] = this.coverage.territoryOwnerFtth
      payload['territoryOwners'] = this.coverage.territoryOwners
      payload['returnBestCoverage'] = true
      payload['channelsWithPriorities'] = true
      payload['sessionId'] = this.session.sessionId
      payload['address'] = {
        province: this.coverage.province,
        town: this.coverage.town,
        streetType: this.coverage.wayType,
        street: this.coverage.wayName,
        number: this.coverage.num,
        bisDuplicate: vertical.Bisduplicate,
        zipCode: this.coverage.postalCode,
        block: vertical.Block,
        stair: vertical.Stair,
        door: vertical.Door,
        letter: vertical.Letter,
        floor: vertical.Floor,
        hand: vertical.Hand1,
        technologies: vertical.Technologies
      }

      let headers = {
        accept: 'application/json',
        accesstoken: this.session.accessToken
      }

      await this.$http.post(this.api + 'Search/FormatCoverage', payload, { headers: headers }).then((response) => {
        if (this.$root.hasData(response.data)) {
          let cobertura = JSON.parse(response.data.cobertura)
          this.formattedCoverage = response.data
          this.formattedCoverage.coberturajson = cobertura
        }
      }).catch((err) => {
        this.$message({
          message: 'Error al obtener la cobertura con formato',
          type: 'error'
        })
        this.error = err
        throw err
      })
    },
    loadFloors () {
      let floors = []
      let hands = []
      this.options.floors = []
      this.options.hands = []
      this.verticals = []

      let verticals = JSON.parse(this.coverage.verticals)
      for (let i in verticals) {
        if (verticals[i].Floor !== null) {
          this.verticals.push(verticals[i])
        }
        if (!floors.includes(verticals[i].FloorId)) {
          floors.push(verticals[i].FloorId)
          this.coverages[verticals[i].FloorId] = {}
          this.options.hands[verticals[i].FloorId] = []
          this.options.floors.push({ label: verticals[i].Floor, value: verticals[i].FloorId })
        }
        if (!hands.includes(verticals[i].Hand1)) {
          hands.push(verticals[i].Hand1)
          this.coverages[verticals[i].FloorId][verticals[i].Hand1] = verticals[i]
          this.options.hands[verticals[i].FloorId].push({ label: verticals[i].Hand1, value: verticals[i].Hand1 })
        }
      }
      this.show.floors = true
    },
    setToken () {
      let tokn = ''
      switch (this.filter.platform) {
        case "1":
          tokn = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjc3MiOiJodHRwczovL2NvYmVydHVyYXJlc291cmNlcy5ibG9iLmNvcmUud2luZG93cy5uZXQvc3R5bGVzL1dlYkRlYWxlci5jc3MiLCJzZWdtZW50byI6IlJFUyIsIm1hcmNhIjoiTUFTTU9WSUwiLCJjYW5hbCI6IldlYkRlYWxlciIsInNmaWQiOiJUTFZOMDE1In0.5StoqaQqsLe7llqC6bcLy7F3lHCtODaWgHpb8PVbC5E'
          break
        case "2":
          tokn = 'eyJhbGciOiJIUzI1NiJ9.eyJzZWdtZW50byI6IlJFUyIsIm1hcmNhIjoiWU9JR08iLCJjYW5hbCI6InRlbGVzYWxlc19wcm8iLCJzZmlkIjoiVk9OMDEwMTFfQ0EifQ.2n1SBNy2SQnG36MxTGobE_SHjdyiWCEgFKdABkYuA6M'
          break
      }

      let params = { tkn: tokn }
      this.$http.get(this.api + 'Configuration/Get', { params: params }).then((response) => {
        this.session = response.data
      })
    },
    formatProperty (name) {
      switch (name) {
        case 'territoryOwner': return 'Propietario'
        case 'speedEstimated': return 'Velocidad Estimada'
        case 'telefonicaCoverage': return 'Cobertura Telefónica'
        default: return name
      }
    },
    getHomeCoverage () {
      this.homeCoverage.characteristics = []
      let coberturas = JSON.parse(this.formattedCoverage.cobertura)

      let coverage = {}
      for (let cobertura of coberturas) {
        for (let item of cobertura.characteristics) {
          coverage[item.name] = item.value
        }
        coverage.technology = this.formattedCoverage.tipoCobertura
        this.homeCoverage.characteristics.push(coverage)
      }
    },
    handleSelect (item) {
      this.place = item.item
      this.show.portal = true
    },
    resetData () {
      this.filter.platform = null
      this.filter.address = ''
      this.filter.portal = null
      this.verticals = []
      this.homeCoverage = {
        characteristics: []
      }
      this.homeCoverageFile = null
      this.homeNeba = null
      this.floor = null
      this.place = null
      this.coverage = {}
      this.secondchance = {}
      this.coverages = {},
      this.token = '',
      this.session = {}
      this.options.floors = []
      this.options.hands = {}
      this.filteredVerticals = []
      this.formattedCoverage = {}
      this.error = {}

      this.show.portal = false
      this.show.floors = false
    }
  }
}
</script>

<style scoped>
  .filter-label {
    margin-bottom: 0px !important;
    font-size: 12px;
  }

  .el-row {
    margin-bottom: 20px;
  }

  .el-col {
    margin-right: 10px;
  }
</style>
