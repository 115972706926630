<template>
  <div id="app">
    <top-nav-bar v-if="logged"></top-nav-bar>
    <transition name="fade">
      <router-view v-if="logged" class="work-area animate"></router-view>
    </transition>
    <login v-if="!logged"></login>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TopNavBar from '@/components/TopNavBar'
import Login from '@/views/Login'
export default {
  name: 'app',
  mounted: function () {
    let vm = this
    this.loadConfig().then(() => {
      vm.$root.checkUser()
      //     .then(response => {
      //   if (response.status === 200) {
      //     // vm.initOptions()
      //   }
      // })
    })
  },
  methods: {
    ...mapActions(['loadConfig'])
  },
  components: { TopNavBar, Login },
  computed: {
    ...mapGetters(['logged'])
  }
}
</script>

<style>
@import '../node_modules/element-ui/lib/theme-chalk/index.css';
/*@import '../static/masmovil.login';*/
body {
  margin: 0px;
  height: 100vh;
  overflow-y: hidden;
}

#navbar {
  position: fixed;
  width: 100vw;
}

#app {
  width: 100vw;
  height: 100vh;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 0px;
}

.el-table .cell {
  word-break: break-word;
}

.work-area {
  width: 100vw;
  height: calc(100vh - 40px);
  position: fixed;
  padding: 10rem 20px 10px 20px;
  box-sizing: border-box;
  overflow: auto;
}
</style>
