<template>
  <div class="form">
    <div class="form-toggle"></div>
    <div class="form-panel one">
      <div class="form-header">
        <img class="logo-login" src="../assets/logos/logo_login.png"/>
      </div>
      <div class="form-content">
        <form>
          <div class="alert alert-danger" v-if="error">
            Datos incorrectos.
          </div>
          <div class="form-group">
            <label for="username">Usuario</label>
            <input type="text" id="username" name="username" v-model="username" required="required"/>
          </div>
          <div class="form-group">
            <label for="password">Contraseña</label>
            <input type="password" id="password" name="password" v-model="password" required="required"/>
          </div>
          <div class="form-group">
            <button type="button" @click="login">Iniciar sesión</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      error: false
    }
  },
  methods: {
    login() {
      const v = this
      this.error = false
      v.$http.post('/d-api/login', {
        "username": v.username,
        "password": v.password
      }).then(response => {
        localStorage.setItem('token', response.data.token)
        v.$http.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;
        const data = {
          'name': response.data.user['first_name'] + ' ' + response.data.user['last_name'],
          'token': response.data.token
        }
        this.$store.commit('login', data)
        v.$router.push('/coverage')
      }).catch(() => {
        this.error = true
      })
    }
  }
}
</script>

<style scoped>
@import "../../static/login/style.css";
@import "../../static/login/bootstrap.min.css";
/*body {*/
/*  padding: 0;*/
/*  margin: 0;*/
/*  height: 100vh;*/
/*  background-color: #D9E0E7;*/
/*}*/
/*#form-login{*/
/*  padding: 0 0.5rem 0 0.5rem;*/
/*  color: #FFFFFF !important;*/
/*}*/
/*.el-form-item__label{*/
/*  color: #FFFFFF !important;*/
/*}*/
/*.login-body {*/
/*  transition-delay: 0.5s;*/
/*  height: 100vh;*/
/*  width: 100vw;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*}*/

/*.login-panel {*/
/*  height: auto;*/
/*  -webkit-box-shadow: 0 5px 7px 5px rgba(0, 0, 0, 0.1);*/
/*  -moz-box-shadow: 0 5px 7px 5px rgba(0, 0, 0, 0.1);*/
/*  box-shadow: 0 5px 7px 5px rgba(0, 0, 0, 0.1);*/
/*  box-sizing: border-box;*/
/*  background-color: #ffffff;*/
/*  overflow: hidden;*/
/*  border-radius: 1rem;*/
/*}*/

/*.logo {*/
/*  width: 40%;*/
/*  max-width: 200px;*/
/*  margin: 15px;*/
/*}*/

/*.login-body .login-panel .ui-g-12 {*/
/*  padding: 16px 0;*/
/*  text-align: center;*/
/*}*/

/*@media (min-width: 641px) {*/
/*  .login-body {*/
/*    !* background: url("../assets/login/login-background.jpeg") top left no-repeat; *!*/
/*    background-size: cover;*/
/*  }*/
/*}*/

/*@media (max-width: 640px) {*/
/*  .login-body .login-panel {*/
/*    width: 100vw;*/
/*  }*/
/*}*/
</style>
