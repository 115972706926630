import {mapGetters} from 'vuex'

export default {
    data() {
        return {}
    },
    methods: {
        calculateTableHeight(rows, header, offset) {
            if (rows !== 0) {
                let val = -10
                if (header < 0) {
                    val = header
                    header = -header
                }
                let altHeight = (document.body.clientHeight - offset) + val
                let dynamicHeight = header + (rows * 30)
                return dynamicHeight > altHeight ? altHeight : dynamicHeight
            } else {
                return header + 60
            }
        },
        makeCall(sale_id, number) {
            var payload = {
                phone: number.toString(),
                sale_id: sale_id
            }
            this.$http.post('/api/v1/sales/' + sale_id + '/call', payload).then(() => {
                this.$message({

                    message: 'Llamada realizada',
                    type: 'success'
                })
            }, () => {
                this.$message({
                    message: 'Error al realizar la llamada',
                    type: 'error'
                })
                return []
            })
        },
        hasData(str) {
            if (str !== undefined && str !== '' && str !== null) {
                return true
            }
            return false
        },
        copyText(item) {
            const el = document.createElement('textarea')
            el.value = item
            el.setAttribute('readonly', '')
            el.style.position = 'absolute'
            el.style.left = '-9999px'
            document.body.appendChild(el)
            el.select()
            document.execCommand('copy')
            document.body.removeChild(el)
        },
        cleanObject(obj) {
            for (let key in obj) {
                let value = obj[key]
                if (value === '' || value === null) {
                    delete obj[key]
                } else {
                    if (/^\d+$/.test(value)) {
                        let i = parseInt(value)
                        if (!isNaN(i)) {
                            obj[key] = i
                        }
                    }
                }
            }
            return obj
        },
        checkUser() {
            let vm = this
            vm.$http.get('/d-api/user-info').then(response => {
                const data = {
                    'name': response.data.user['first_name'] + ' ' + response.data.user['last_name'],
                    'token': response.data.token
                }
                this.$store.commit('login', data)
            }).catch(function (error) {
                if (error.response.status === 401) {
                    vm.$store.commit('logout')
                    vm.$router.push('/login')
                }
            })

            // return this.$http.get('/auth/current').then(response => {
            //   if (response.status === 200) {
            //     this.$store.commit('login', response.data)
            //   }
            //   return response
            // }).catch(function (error) {
            //   if (error.response.status === 401) {
            //     vm.$store.commit('logout')
            //     vm.$router.push('/login')
            //   }
            //   return error
            // })
        },
        async checkItem(type, spec, value) {
            if (this.$root.hasData(value)) {
                if (this.$root.hasData(spec)) {
                    spec = '/' + spec
                } else {
                    spec = ''
                }
                return this.$http.get('https://' + this.config.checker.host + '/api/v1/' + type + '/' + value + spec).then(response => {
                    if (response.data) {
                        this.$message({
                            message: 'El dato es válido',
                            type: 'success'
                        })
                    } else {
                        this.$message({
                            message: 'El dato no es válido',
                            type: 'error'
                        })
                    }
                    return response.data
                }).catch(function (error) {
                    this.$message({
                        message: 'Error validando el dato',
                        type: 'error'
                    })
                    throw error
                })
            }
        },
        async checkRegex(regex_string, value) {
            if (this.$root.hasData(value)) {
                if (this.$root.hasData(regex_string)) {
                    const patron = new RegExp(regex_string)
                    if (patron.test(value)) {
                        this.$message({
                            message: 'El dato es válido',
                            type: 'success'
                        })
                        return true
                    } else {
                        this.$message({
                            message: 'El dato no cumple el patrón requerido',
                            type: 'error'
                        })
                        return false
                    }
                }
            }
        }
    },
    computed: {
        ...mapGetters(['config'])
    }
}
