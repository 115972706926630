import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/es'
import 'vue-awesome/icons'
import store from './store/store'
import customMixins from './mixins'
import Icon from 'vue-awesome/components/Icon'
import 'moment/locale/es';

const token = localStorage.getItem('token')
if(token !== null){
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

Vue.use(require('vue-moment'))
Vue.use(VueAxios, axios)
Vue.use(ElementUI, { locale })
Vue.component('icon', Icon)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  mixins: [customMixins],
  render: h => h(App)
}).$mount("#app");
