import Vue from "vue";
import Router from "vue-router";
// import SalesNew from '@/views/Sales/New'
// import SalesList from '@/views/Sales/List'
// import SignalsNew from '@/views/Signals/New'
// import SignalsList from '@/views/Signals/List'
// import ClientsList from '@/views/Clients/List'
// import ProductsList from '@/views/Products/List'
// import EventsConfig from '@/views/Events/Config'
// import EventsList from '@/views/Events/List'
// import EventsNew from '@/views/Events/New'
// import Settings from '@/views/Settings/Index'
import Coverage from '@/views/Coverage'
// import Informa from '@/views/Informa/Index'
// import Vista from '@/views/Vista/Index'
// import Phones from '@/components/Mobiles/App'

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/login',
      redirect: '/'
    }, {
      path: '/logout',
      redirect: '/'
    },
    // , {
    //   path: '/clients',
    //   name: 'Clientes::Listar',
    //   component: ClientsList
    // }, {
    //   path: '/products',
    //   name: 'Productos::Listar',
    //   component: ProductsList
    // },
    {
      path: '/coverage',
      name: 'Cobertura',
      component: Coverage
    }
    // , {
    //   path: '/phones',
    //   name: 'Terminales',
    //   component: Phones
    // }, {
    //   path: '/sales',
    //   name: 'Ventas::Listar',
    //   component: SalesList
    // }, {
    //   path: '/sales/new/',
    //   name: 'Ventas::Nueva',
    //   component: SalesNew
    // }, {
    //   path: '/sales/new/:lc_id',
    //   name: 'Ventas::Nueva::Lead',
    //   component: SalesNew
    // }, {
    //   path: '/sales/new/:lc_id/:sig_id',
    //   name: 'Ventas::Nueva::Signal',
    //   component: SalesNew
    // }, {
    //   path: '/signals/new/:lc_id',
    //   name: 'Señales::Nueva::Lead',
    //   component: SignalsNew
    // }, {
    //   path: '/signals',
    //   name: 'Señales::Listar',
    //   component: SignalsList
    // }, {
    //   path: '/events/config',
    //   name: 'Eventos::Configurar',
    //   component: EventsConfig
    // }, {
    //   path: '/events',
    //   name: 'Eventos::List',
    //   component: EventsList
    // }, {
    //   path: '/events/new/:lc_id',
    //   name: 'Eventos::Nuevo::Lead',
    //   component: EventsNew
    // }, {
    //   path: '/events/new/',
    //   name: 'Eventos::Nueva',
    //   component: EventsNew
    // }, {
    //   path: '/informa',
    //   name: 'Informa.es',
    //   component: Informa
    // }, {
    //   path: '/vista',
    //   name: 'Vista360',
    //   component: Vista
    // }, {
    //   path: '/settings',
    //   name: 'Settings',
    //   component: Settings
    // }
  ],
  data () {
    return {
      ruta: ''
    }
  }
});
